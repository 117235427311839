<template>
  <div id="userLayout" :class="['user-layout-wrapper', isMobile && 'mobile']">
    <div class="container">
      <div class="box">
        <div class="img">
          <img src="@/assets/logo.png" alt="" />
        </div>
        <div class="title">
          <p>大掌柜零售通-{{ info.tenantName || '--' }}</p>
          <div class="line"></div>
        </div>
        <div class="user-layout-content">
          <router-view />
          <div
            class="footer"
            style="color: rgba(212, 48, 48, 1) !important; font-size: 13px"
            v-if="days.substring(0, 1) != '-'"
          >
            服务到期时间为{{ time.timeText ?? 0 }}(剩余{{ days == '' ? 0 : days }})，祝您使用愉快！
          </div>
          <div class="footer" style="color: rgba(212, 48, 48, 1) !important; font-size: 13px" v-else>已过期</div>
        </div>
      </div>
      <div class="labelTitle">
        Copyright© 2025 福建华软智通科技有限公司 ALL Rights Reserved
        <a href="https://beian.miit.gov.cn" target="_blank">闽ICP备2024062617号-1</a>
      </div>
    </div>
  </div>
</template>

<script>
import { deviceMixin } from '@/store/device-mixin'
import SelectLang from '@/components/SelectLang'
import { getTenantUsingGET } from '@/api/api/quanxianrenzhengguanli.ts'
import { getTenantIdByDomain } from '@/api/login'
import { formatDate } from '@/utils/formatDate'
import { computer } from '@/utils/computer'
export default {
  name: 'UserLayout',
  components: {
    SelectLang,
  },
  data() {
    return {
      info: {},
      time: '',
      days: '',
      timer: null,
      tenantId: undefined,
    }
  },
  mixins: [deviceMixin],
  mounted() {
    // const octalRegex = /^(0o)?[0-7]+$/i
    // const tenantIdStr = window.location.host.split('.')?.[0]
    // const tenantId = octalRegex.test(tenantIdStr) ? tenantIdStr : 1

    getTenantIdByDomain(window.location.host)
      // getTenantIdByDomain('localhost:8002')
      .then((res) => {
        if (res.code == 200) {
          this.timeStart()
          this.info = res.data
          this.time = formatDate(new Date(res.data.expire))
        }
      })
      .catch((error) => {
        this.$message.error(((error.response || {}).data || {}).msg || '请求出现错误')
      })
  },
  methods: {
    timeStart() {
      // 设置定时器
      this.timer = setInterval(() => {
        this.days = computer(this.info.expire)
      }, 1200)
    },
  },
  beforeDestroy() {
    this.timer = null
    document.body.classList.remove('userLayout')
  },
}
</script>

<style lang="less" scoped>
#userLayout.user-layout-wrapper {
  height: 100%;

  &.mobile {
    .container {
      .main {
        width: 98%;
        max-width: 368px;
      }
    }
  }

  .container {
    position: relative;
    width: 100%;
    width: 100%;
    min-height: 100%;
    background: url('@/assets/bg.jpg');
    background-size: 100%;
    //padding: 50px 0 84
    background-size: 100%;
    position: relative;
    .box {
      width: 517px;
      height: 627px;
      opacity: 1;
      border-radius: 30px;
      background: rgba(255, 255, 255, 0.7);

      border: 2px solid rgba(255, 255, 255, 1);
      position: absolute;
      top: 107px;
      right: 230px;
      text-align: center;
      .img {
        margin-top: 39px;

        img {
          width: 119px;
          height: 91px;
        }
      }
      .title {
        margin-top: 28px;
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          font-size: 26px;
          color: rgba(0, 0, 0, 1);
          font-weight: 400;
        }
        .line {
          width: 79px;
          height: 8px;
          margin-top: -10px;
          background: rgba(69, 131, 250, 1);
        }
      }
    }

    a {
      text-decoration: none;
    }
  }
  @media screen and (max-width: 750px) {
    /* 在此处编写对应的样式 */
    .user-layout-content {
      position: relative;
      top: 50%;
      left: 50%;
      width: 300px !important;
      padding: 50px 0 24px;
      background: #fff;
      border: #394557 3px solid;
      transform: translate(-50%);
      .main {
        width: 100% !important;
        min-width: 260px !important;
        margin: 0 auto !important;
        padding: 0 20px !important;
      }
    }
  }
  .labelTitle {
    width: 100%;
    position: absolute;
    text-align: center;
    bottom: 20px;
  }
}
</style>
