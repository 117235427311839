// eslint-disable-next-line
import { UserLayout, BasicLayout, BlankLayout } from '@/layouts'
const RouteView = {
  name: 'RouteView',
  render: (h) => h('router-view'),
}

export const asyncRouterMap = [
  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    meta: { title: '首页' },
    redirect: '/home',
    children: [
      // dashboard

      {
        path: '/home',
        name: 'Home',
        redirect: '/home/index',
        component: RouteView,
        hideChildrenInMenu: true,
        meta: { title: '首页', keepAlive: true, icon: 'home', permission: ['dashboard'] },
        children: [
          // dashboard
          {
            path: '/home/index',
            name: 'HomeIndex',
            icon: 'a-icon-home',
            component: () => import('@/views/home/index.vue'),
            meta: { title: '首页', keepAlive: true, permission: ['dashboard'] },
          },
          {
            path: '/home/setting',
            name: 'HomeSetting',
            component: () => import('@/views/home/setting.vue'),
            meta: { title: '常用功能设置', keepAlive: true, permission: ['dashboard'] },
          },
          {
            path: '/home/alllogs',
            name: 'HomeAllLogs',
            component: () => import('@/views/home/alllogs.vue'),
            meta: { title: '更新日志', keepAlive: true, permission: ['dashboard'] },
          },
        ],
      },
      {
        path: '/my',
        name: 'My',
        redirect: '/my/info',
        component: RouteView,
        meta: { title: '我的', keepAlive: true, icon: 'user', permission: ['dashboard'] },
        children: [
          {
            path: '/my/info',
            name: 'Info',
            component: () => import('@/views/my/info/index.vue'),
            meta: { title: '个人信息', keepAlive: true, permission: ['dashboard'] },
          },
          {
            path: '/my/message',
            name: 'Message',
            component: () => import('@/views/my/message/index.vue'),
            meta: { title: '我的消息', keepAlive: true, permission: ['dashboard'] },
          },
          {
            path: '/my/message/detail/:id',
            name: 'MessageDetail',
            hidden: true,
            component: () => import('@/views/my/message/detail.vue'),
            meta: { title: '消息详情', keepAlive: true, permission: ['dashboard'] },
          },
          {
            path: '/my/toolbox',
            name: 'Toolbox',
            component: () => import('@/views/my/toolbox/index.vue'),
            meta: { title: '工具箱', keepAlive: true, permission: ['dashboard'] },
          },
        ],
      },
      {
        path: '/shop',
        name: 'Shop',
        redirect: '/shop/index',
        component: BlankLayout,
        hideChildrenInMenu: true,
        meta: { title: '店面管理', keepAlive: true, icon: 'cluster', permission: ['dashboard'] },
        children: [
          {
            path: '/shop/index',
            name: 'ShopIndex',
            component: () => import('@/views/shop/index.vue'),
            meta: { title: '店面管理', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/shop/:id',
            name: 'ShopDetail',
            component: () => import('@/views/shop/details.vue'),
            meta: { title: '店面详情', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/shop/mapDetail/:id',
            name: 'MapDetail',
            component: () => import('@/views/shop/mapDetail.vue'),
            meta: { title: '编辑店面定位', keepAlive: false, permission: ['dashboard'] },
          },
        ],
      },
      {
        path: '/user',
        name: 'UserManage',
        redirect: '/user/office/index',
        component: RouteView,
        meta: { title: '员工管理', keepAlive: true, icon: 'user', permission: ['dashboard'] },
        children: [
          {
            path: '/user/office',
            name: 'Office',
            component: BlankLayout,
            redirect: '/user/office/index',
            hideChildrenInMenu: true,
            meta: { title: '职务列表', keepAlive: false, permission: ['dashboard'] },
            children: [
              {
                path: '/user/office/index',
                name: 'OfficeIndex',
                component: () => import('@/views/userManage/office/index.vue'),
                meta: { title: '职务列表', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/user/office/officeCompetence/:id',
                name: 'OfficeCompetence',
                component: () => import('@/views/userManage/office/officeCompetence.vue'),
                meta: { title: '员工详情', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/user/user',
            name: 'User',
            redirect: '/user/user/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '员工列表', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/user/user/index',
                name: 'UserIndex',
                component: () => import('@/views/userManage/user/index.vue'),
                meta: { title: '员工列表', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/user/user/:id',
                name: 'UserDetail',
                component: () => import('@/views/userManage/user/details.vue'),
                meta: { title: '员工详情', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/user/competenceSetting',
            name: 'CompetenceSetting',
            redirect: '/user/competenceSetting/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '权限设置', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/user/competenceSetting/index',
                name: 'CompetenceSettingIndex',
                component: () => import('@/views/userManage/competenceSetting/index.vue'),
                meta: { title: '权限设置', keepAlive: false, permission: ['dashboard'] },
              },
              //改
              {
                path: '/user/competenceSetting/detail',
                name: 'CompetenceSettingDetail',
                component: () => import('@/views/userManage/competenceSetting/detail.vue'),
                meta: { title: '权限详情', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/user/wage',
            name: 'Wage',
            redirect: '/user/wage/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '工资列表', keepAlive: false, permission: ['dashboard'] },
            children: [
              {
                path: '/user/wage/index',
                name: 'WageIndex',
                component: () => import('@/views/userManage/wage/index.vue'),
                meta: { title: '工资列表', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/user/wage/:id',
                name: 'WageDetail',
                component: () => import('@/views/userManage/wage/details.vue'),
                meta: { title: '工资详情', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/user/wagesetting',
                name: 'DetailSetting',
                component: () => import('@/views/userManage/wage/detailSetting.vue'),
                meta: { title: '工资设置', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/user/wage/orderGoods/:id',
                name: 'OrderGoods',
                component: () => import('@/views/userManage/wage/orderGoods.vue'),
                meta: { title: '商品提成详情', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/user/wage/orderMobile/:id',
                name: 'OrderMobile',
                component: () => import('@/views/userManage/wage/orderMobile.vue'),
                meta: { title: '运营商提成详情', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
        ],
      },
      {
        path: '/agent',
        name: 'Agent',
        redirect: '/agent/index',
        component: RouteView,
        hideChildrenInMenu: true,
        meta: { title: '供应商&经销商', keepAlive: true, icon: 'shop', permission: ['dashboard'] },
        children: [
          {
            path: '/agent/index',
            name: 'AgentIndex',
            component: () => import('@/views/agent/index.vue'),
            meta: { title: '供应商&经销商', keepAlive: true, permission: ['dashboard'] },
          },
          {
            path: '/agent/leadin',
            name: 'AgentLeadin',
            component: () => import('@/views/agent/leadin.vue'),
            meta: { title: '经销商导入', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/agent/finance',
            name: 'AgentFinance',
            component: () => import('@/views/agent/finance.vue'),
            meta: { title: '财务统计', keepAlive: true, permission: ['dashboard'] },
          },
          {
            path: '/agent/record',
            name: 'AgentRecord',
            component: () => import('@/views/agent/record.vue'),
            meta: { title: '财务记录', keepAlive: true, permission: ['dashboard'] },
          },
          {
            path: '/agent/merge',
            name: 'AgentMerge',
            component: () => import('@/views/agent/merge.vue'),
            meta: { title: '合并供应商', keepAlive: true, permission: ['dashboard'] },
          },
          {
            path: '/agent/:id',
            name: 'AgentDetail',
            component: () => import('@/views/agent/details.vue'),
            meta: { title: '供应商&经销商详情', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/agent/account/:id',
            name: 'AgentAccountDetail',
            component: () => import('@/views/agent/accountdetails.vue'),
            meta: { title: '账户详情', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/agent/order/:id',
            name: 'AgentOrderDetail',
            component: () => import('@/views/agent/orderdetails.vue'),
            meta: { title: '订单详情', keepAlive: false, permission: ['dashboard'] },
          },
        ],
      },
      {
        path: '/goods',
        name: 'Goods',
        redirect: '/goods/brand',
        component: RouteView,
        meta: { title: '商品管理', keepAlive: true, icon: 'shopping', permission: ['dashboard'] },
        children: [
          {
            path: '/goods/brand',
            name: 'GoodsBrand',
            redirect: '/goods/brand/index',
            hideChildrenInMenu: true,
            component: BlankLayout,
            meta: { title: '品牌管理', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/goods/brand/index',
                name: 'GoodsBrandIndex',
                component: () => import('@/views/goods/brand/index.vue'),
                meta: { title: '品牌管理', keepAlive: true, permission: ['dashboard'] },
              },
              {
                path: '/goods/brand/merge',
                name: 'GoodsBrandMerge',
                component: () => import('@/views/goods/brand/merge.vue'),
                meta: { title: '合并品牌', keepAlive: true, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/goods/classify',
            name: 'GoodsClassify',
            redirect: '/goods/classify/index',
            hideChildrenInMenu: true,
            component: BlankLayout,
            meta: { title: '分类管理', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/goods/classify/index',
                name: 'GoodsClassifyIndex',
                component: () => import('@/views/goods/classify/index.vue'),
                meta: { title: '分类管理', keepAlive: true, permission: ['dashboard'] },
              },
              {
                path: '/goods/classify/merge',
                name: 'GoodsClassifyMerge',
                component: () => import('@/views/goods/classify/merge.vue'),
                meta: { title: '合并分类', keepAlive: true, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/goods/name',
            name: 'GoodsName',
            redirect: '/goods/name/index',
            hideChildrenInMenu: true,
            component: BlankLayout,
            meta: { title: '商品名管理', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/goods/name/index',
                name: 'GoodsNameIndex',
                component: () => import('@/views/goods/name/index.vue'),
                meta: { title: '商品名管理', keepAlive: true, permission: ['dashboard'] },
              },
              {
                path: '/goods/name/merge',
                name: 'GoodsNameMerge',
                component: () => import('@/views/goods/name/merge.vue'),
                meta: { title: '合并商品', keepAlive: true, permission: ['dashboard'] },
              },
              {
                path: '/goods/name/date',
                name: 'GoodsNameDate',
                component: () => import('@/views/goods/name/date.vue'),
                meta: { title: '重设生效日期', keepAlive: true, permission: ['dashboard'] },
              },
              {
                path: '/goods/name/leadin',
                name: 'GoodsNameLeadin',
                component: () => import('@/views/goods/name/leadin.vue'),
                meta: { title: '导入商品', keepAlive: true, permission: ['dashboard'] },
              },
              {
                path: '/goods/name/:id',
                name: 'GoodsNameDetail',
                component: () => import('@/views/goods/name/detail.vue'),
                meta: { title: '商品详情', keepAlive: true, permission: ['dashboard'] },
              },
              {
                path: '/goods/name/change/:id',
                name: 'GoodsNameChange',
                component: () => import('@/views/goods/name/change.vue'),
                meta: { title: '商品成本变更记录', keepAlive: true, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/goods/price',
            name: 'GoodsPrice',
            component: () => import('@/views/goods/price/index.vue'),
            meta: { title: '价格表', keepAlive: true, permission: ['dashboard'] },
          },
          {
            path: '/goods/snSearch',
            name: 'GoodsSnSearch',
            hidden: true,
            component: () => import('@/views/goods/snSearch/index.vue'),
            meta: { title: '商品串码速查', keepAlive: true, permission: ['dashboard'] },
          },
        ],
      },
      {
        path: '/purchasing',
        name: 'Purchasing',
        redirect: '/purchasing/channel',
        component: RouteView,
        meta: { title: '采购管理', keepAlive: true, icon: 'shopping-cart', permission: ['dashboard'] },
        children: [
          {
            path: '/purchasing/channel',
            name: 'PurchasingChannel',
            component: () => import('@/views/purchasing/channel/index.vue'),
            meta: { title: '渠道管理', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/purchasing/inStore',
            name: 'InStore',
            redirect: '/purchasing/inStore/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '采购入库', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/purchasing/inStore/index',
                name: 'InStoreIndex',
                component: () => import('@/views/purchasing/inStore/index.vue'),
                meta: { title: '采购入库', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/purchasing/inStore/:id',
                name: 'InStoreDetail',
                component: () => import('@/views/purchasing/inStore/details.vue'),
                meta: { title: '手动入库', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/purchasing/inStoreAuto',
            name: 'InStoreAuto',
            redirect: '/purchasing/inStoreAuto/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '自动编码入库', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/purchasing/inStoreAuto/index',
                name: 'InStoreAutoIndex',
                component: () => import('@/views/purchasing/inStoreAuto/index.vue'),
                meta: { title: '自动编码入库', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/purchasing/inStoreAuto/:id',
                name: 'InStoreAutoDetail',
                component: () => import('@/views/purchasing/inStoreAuto/details.vue'),
                meta: { title: '手动入库', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/purchasing/inStoreRecord',
            name: 'InStoreRecord',
            redirect: '/purchasing/inStoreRecord/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '入库记录', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/purchasing/inStoreRecord/index',
                name: 'InStoreRecordIndex',
                component: () => import('@/views/purchasing/inStoreRecord/index.vue'),
                meta: { title: '入库记录', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/purchasing/inStoreRecord/:id',
                name: 'InStoreRecordDetail',
                component: () => import('@/views/purchasing/inStoreRecord/details.vue'),
                meta: { title: '入库记录', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/purchasing/inStoreRecord/detail/:id',
                name: 'InStoreRecordDetail',
                component: () => import('@/views/purchasing/inStoreRecord/detail.vue'),
                meta: { title: '入库记录', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/purchasing/inStoreRecord/add/:id',
                name: 'InStoreRecordAdd',
                component: () => import('@/views/purchasing/inStoreRecord/add.vue'),
                meta: { title: '追加商品', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/purchasing/backstore',
            name: 'Backstore',
            redirect: '/purchasing/backstore/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '采购退库', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/purchasing/backstore/index',
                name: 'BackstoreIndex',
                component: () => import('@/views/purchasing/backstore/index.vue'),
                meta: { title: '采购退库', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/purchasing/backstore/details',
                name: 'BackstoreIndexDetail',
                component: () => import('@/views/purchasing/backstore/details.vue'),
                meta: { title: '采购退库', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/purchasing/backstorerecord',
            name: 'BackStoreRecord',
            redirect: '/purchasing/backstorerecord/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '退库记录', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/purchasing/backstorerecord/index',
                name: 'BackStoreRecordIndex',
                component: () => import('@/views/purchasing/backstorerecord/index.vue'),
                meta: { title: '退库记录', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/purchasing/backstorerecord/:id',
                name: 'BackStoreRecordDetail',
                component: () => import('@/views/purchasing/backstorerecord/details.vue'),
                meta: { title: '退库记录', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/purchasing/backstorerecord/detail/:id',
                name: 'BackStoreRecordDetailDetail',
                component: () => import('@/views/purchasing/backstorerecord/detail.vue'),
                meta: { title: '退库记录详情', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/purchasing/backstorerecord/add/:id',
                name: 'BackStoreRecordDetailAdd',
                component: () => import('@/views/purchasing/backstorerecord/add.vue'),
                meta: { title: '追加退库', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/purchasing/protectprice',
            name: 'ProtectPrice',
            component: () => import('@/views/purchasing/protectprice/index.vue'),
            meta: { title: '商品保价', keepAlive: true, permission: ['dashboard'] },
          },
          {
            path: '/purchasing/ProtectPriceRecord',
            name: 'ProtectPriceRecord',
            redirect: '/purchasing/ProtectPriceRecord/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '保价记录', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/purchasing/ProtectPriceRecord/index',
                name: 'ProtectPriceRecordIndex',
                component: () => import('@/views/purchasing/protectPriceRecord/index.vue'),
                meta: { title: '保价记录', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/purchasing/ProtectPriceRecord/alldetails',
                name: 'ProtectPriceRecordAllDetails',
                component: () => import('@/views/purchasing/protectPriceRecord/details.vue'),
                meta: { title: '保价详情', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/purchasing/ProtectPriceRecord/:id',
                name: 'ProtectPriceRecordDetail',
                component: () => import('@/views/purchasing/protectPriceRecord/detail.vue'),
                meta: { title: '保价详情', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/purchasing/ProtectPriceRecord/edit/:id',
                name: 'ProtectPriceRecordEdit',
                component: () => import('@/views/purchasing/protectPriceRecord/edit.vue'),
                meta: { title: '编辑保价', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/purchasing/returnFactoryOutStore',
            name: 'ReturnFactoryOutStore',
            redirect: '/purchasing/returnFactoryOutStore/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '返厂退库', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/purchasing/returnFactoryOutStore/index',
                name: 'ReturnFactoryOutStoreIndex',
                component: () => import('@/views/purchasing/returnFactoryOutStore/index.vue'),
                meta: { title: '返厂退库', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/purchasing/returnFactoryOutStore/details',
                name: 'ReturnFactoryOutStoreDetail',
                component: () => import('@/views/purchasing/returnFactoryOutStore/details.vue'),
                meta: { title: '返厂退库', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/purchasing/returnFactoryInStore',
            name: 'ReturnFactoryInStore',
            redirect: '/purchasing/returnFactoryInStore/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '返厂入库', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/purchasing/returnFactoryInStore/index',
                name: 'ReturnFactoryInStoreIndex',
                component: () => import('@/views/purchasing/returnFactoryInStore/index.vue'),
                meta: { title: '返厂入库', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/purchasing/returnFactoryInStore/details',
                name: 'ReturnFactoryInStoreDetail',
                component: () => import('@/views/purchasing/returnFactoryInStore/details.vue'),
                meta: { title: '返厂入库', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/purchasing/returnFactoryShopRecord',
            name: 'ReturnFactoryShopRecord',
            redirect: '/purchasing/returnFactoryShopRecord/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '返厂记录', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/purchasing/returnFactoryShopRecord/index',
                name: 'ReturnFactoryShopRecordIndex',
                component: () => import('@/views/purchasing/returnFactoryShopRecord/index.vue'),
                meta: { title: '返厂记录', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/purchasing/returnFactoryShopRecord/:id',
                name: 'ReturnFactoryShopRecordDetail',
                component: () => import('@/views/purchasing/returnFactoryShopRecord/details.vue'),
                meta: { title: '返厂记录', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/purchasing/returnFactoryShopRecord/detail/:id',
                name: 'ReturnFactoryShopRecordDetail',
                component: () => import('@/views/purchasing/returnFactoryShopRecord/detail.vue'),
                meta: { title: '返厂记录', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
        ],
      },
      {
        path: '/stock',
        name: 'stock',
        redirect: '/stock/changePrice',
        component: RouteView,
        meta: { title: '库存管理', keepAlive: true, icon: 'inbox', permission: ['dashboard'] },
        children: [
          {
            path: '/stock/find',
            name: 'find',
            component: () => import('@/views/stock/find.vue'),
            meta: { title: '库存查询', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/stock/distribution',
            name: 'Distribution',
            redirect: '/stock/distribution/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '库存分布', keepAlive: false, permission: ['dashboard'] },
            children: [
              {
                path: '/stock/distribution/index',
                name: 'DistributionIndex',
                component: () => import('@/views/stock/distribution/index.vue'),
                meta: { title: '库存分布', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/stock/distribution/detail',
                name: 'DistributionDetail',
                component: () => import('@/views/stock/distribution/detail.vue'),
                meta: { title: '库存详情', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/stock/storeShop',
            name: 'StoreShop',
            redirect: '/stock/storeShop/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '库存统计', keepAlive: false, permission: ['dashboard'] },
            children: [
              {
                path: '/stock/storeShop/index',
                name: 'StoreShopIndex',
                component: () => import('@/views/stock/storeShop/index.vue'),
                meta: { title: '库存统计', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/stock/storeShop/:id',
                name: 'StoreShopDetail',
                component: () => import('@/views/stock/storeShop/detail.vue'),
                meta: { title: '库存统计详情', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/stock/history',
            name: 'stockHistory',
            redirect: '/stock/history/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '历史库存', keepAlive: false, permission: ['dashboard'] },
            children: [
              {
                path: '/stock/history/index',
                name: 'StockHistoryIndex',
                component: () => import('@/views/stock/history/index.vue'),
                meta: { title: '历史库存', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/stock/history/:id',
                name: 'StockHistoryDetail',
                component: () => import('@/views/stock/history/detail.vue'),
                meta: { title: '历史库存详情', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/stock/warn/index',
            name: 'warnIndex',
            component: () => import('@/views/stock/warn/index.vue'),
            meta: { title: '库存预警', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/stock/storeAge',
            name: 'StoreAge',
            redirect: '/stock/storeAge/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '超龄库存', keepAlive: false, permission: ['dashboard'] },
            children: [
              {
                path: '/stock/storeAge/index',
                name: 'StoreAgeIndex',
                component: () => import('@/views/stock/storeAge/index.vue'),
                meta: { title: '超龄库存', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/stock/storeAge/:id',
                name: 'StoreAgeDetail',
                component: () => import('@/views/stock/storeAge/details.vue'),
                meta: { title: '超龄库存详情', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/stock/changePrice',
            name: 'ChangePrice',
            component: () => import('@/views/stock/changePrice/index.vue'),
            meta: { title: '商品调价', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/stock/changePriceRecord',
            name: 'ChangePriceRecord',
            redirect: '/stock/changePriceRecord/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '调价记录', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/stock/changePriceRecord/index',
                name: 'ChangePriceRecordIndex',
                component: () => import('@/views/stock/changePriceRecord/index.vue'),
                meta: { title: '调价记录', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/stock/changePriceRecord/:id',
                name: 'ChangePriceRecordDetail',
                component: () => import('@/views/stock/changePriceRecord/detail.vue'),
                meta: { title: '调价记录详情', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/stock/changePriceChange/:id',
                name: 'ChangePriceRecordChange',
                component: () => import('@/views/stock/changePriceRecord/change.vue'),
                meta: { title: '编辑调价记录', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/stock/lose',
            name: 'Lose',
            redirect: '/stock/lose/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '商品报损', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/stock/lose/index',
                name: 'LoseIndex',
                component: () => import('@/views/stock/lose/index.vue'),
                meta: { title: '商品报损', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/stock/lose/details',
                name: 'LoseDetail',
                component: () => import('@/views/stock/lose/details.vue'),
                meta: { title: '商品报废损失', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/stock/loseRecord',
            name: 'LoseRecord',
            redirect: '/stock/loseRecord/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '报损记录', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/stock/loseRecord/index',
                name: 'LoseRecordIndex',
                component: () => import('@/views/stock/loseRecord/index.vue'),
                meta: { title: '报损记录', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/stock/loseRecord/:id',
                name: 'LoseRecordDetail',
                component: () => import('@/views/stock/loseRecord/detail.vue'),
                meta: { title: '报损记录详情', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
        ],
      },

      {
        path: '/wholesale',
        name: 'Wholesale',
        redirect: '/wholesale/back/index',
        component: RouteView,
        meta: { title: '批发业务', keepAlive: true, icon: 'barcode', permission: ['dashboard'] },
        children: [
          {
            path: '/wholesale/sell',
            name: 'WholesaleSell',
            redirect: '/wholesale/sell/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '批发出库', keepAlive: false, permission: ['dashboard'] },
            children: [
              {
                path: '/wholesale/sell/index',
                name: 'WholesaleSellIndex',
                component: () => import('@/views/wholesale/sell/index.vue'),
                meta: { title: '批发出库', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/wholesale/sell/sellshop/:id',
                name: 'WholesaleSellShop',
                component: () => import('@/views/wholesale/sell/sellshop.vue'),
                meta: { title: '批发出库', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/wholesale/sell/sellshoplarge/:id',
                name: 'WholesaleSellShopLarge',
                component: () => import('@/views/wholesale/sell/sellshoplarge.vue'),
                meta: { title: '批发出库', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/wholesale/sellrecord',
            name: 'WholesaleSellRecord',
            redirect: '/wholesale/sellrecord/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '出库记录', keepAlive: false, permission: ['dashboard'] },
            children: [
              {
                path: '/wholesale/sellrecord/index',
                name: 'WholesaleSellRecordIndex',
                component: () => import('@/views/wholesale/sellrecord/index.vue'),
                meta: { title: '出库记录', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/wholesale/sellrecord/:id',
                name: 'WholesaleSellRecordDetail',
                component: () => import('@/views/wholesale/sellrecord/details.vue'),
                meta: { title: '出库记录', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/wholesale/sellrecord/detail/:id',
                name: 'WholesaleSellRecordDetailDetail',
                component: () => import('@/views/wholesale/sellrecord/detail.vue'),
                meta: { title: '出库记录详情', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/wholesale/sellrecord/edit/:id',
                name: 'WholesaleSellRecordDetailEdit',
                component: () => import('@/views/wholesale/sellrecord/add.vue'),
                meta: { title: '编辑订单', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },


          {
            path: '/wholesale/revoke',
            name: 'revoke',
            redirect: '/wholesale/revoke/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '出库撤销记录', keepAlive: false, permission: ['dashboard'] },
            children: [
              {
                path: '/wholesale/revoke/index',
                name: 'revokeIndex',
                component: () => import('@/views/wholesale/revoke/index.vue'),
                meta: { title: '店面选择', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/wholesale/revoke/:id',
                name: 'revokeDetail',
                component: () => import('@/views/wholesale/revoke/detail.vue'),
                meta: { title: '出库撤销记录', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/wholesale/revoke/detail/:id',
                name: 'revokeDetails',
                component: () => import('@/views/wholesale/revoke/details.vue'),
                meta: { title: '出库撤销记录详情', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },

          {
            path: '/wholesale/back',
            name: 'Back',
            redirect: '/wholesale/back/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '批发退库', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/wholesale/back/index',
                name: 'Backindex',
                component: () => import('@/views/wholesale/back/index.vue'),
                meta: { title: '批发退库', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/wholesale/back/:id',
                name: 'BackDetails',
                component: () => import('@/views/wholesale/back/details.vue'),
                meta: { title: '批发退库', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/wholesale/backShopList/:id',
                name: 'BackShopList',
                component: () => import('@/views/wholesale/back/backShopList.vue'),
                meta: { title: '批发退库', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/wholesale/backRecord',
            name: 'BackRecord',
            redirect: '/wholesale/backRecord/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '退库记录', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/wholesale/backRecord/index',
                name: 'BackRecordIndex',
                component: () => import('@/views/wholesale/backRecord/index.vue'),
                meta: { title: '退库记录', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/wholesale/backRecord/:id',
                name: 'BackRecordDetail',
                component: () => import('@/views/wholesale/backRecord/details.vue'),
                meta: { title: '退库记录', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/wholesale/backRecord/detail/:id',
                name: 'BackRecordDetailDetail',
                component: () => import('@/views/wholesale/backRecord/detail.vue'),
                meta: { title: '出库记录详情', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/wholesale/backRecord/edit/:id',
                name: 'BackRecordDetailEdit',
                component: () => import('@/views/wholesale/backRecord/add.vue'),
                meta: { title: '编辑订单', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },

        ],
      },

      {
        path: '/mobile',
        name: 'Mobile',
        redirect: '/mobile/brand',
        component: RouteView,
        meta: { title: '运营商业务', keepAlive: true, icon: 'dribbble', permission: ['dashboard'] },
        children: [
          {
            path: '/mobile/brand',
            name: 'Brand',
            component: () => import('@/views/mobile/brand/index.vue'),
            meta: { title: '运营商管理', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/mobile/channel',
            name: 'Channel',
            component: () => import('@/views/mobile/channel/index.vue'),
            meta: { title: '渠道管理', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/mobile/eid',
            name: 'Eid',
            component: () => import('@/views/mobile/eid/index.vue'),
            meta: { title: '工号管理', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/mobile/kind',
            name: 'Kind',
            component: () => import('@/views/mobile/kind/index.vue'),
            meta: { title: '业务分类管理', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/mobile/mobile',
            name: 'Mobile',
            redirect: '/mobile/mobile/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '运营商业务列表', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/mobile/mobile/index',
                name: 'MobileIndex',
                component: () => import('@/views/mobile/mobile/index.vue'),
                meta: { title: '运营商业务列表', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/mobile/mobile/leadin',
                name: 'MobileLeadin',
                component: () => import('@/views/mobile/mobile/leadin.vue'),
                meta: { title: '运营商业务导入', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/mobile/mobile/:id',
                name: 'MobileDetail',
                component: () => import('@/views/mobile/mobile/details.vue'),
                meta: { title: '运营商业务详情', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          // {
          //     path: '/mobile/mobile',
          //     name: 'Mobile',
          //     component: () =>
          //         import ('@/views/mobile/mobile/index.vue'),
          //     meta: { title: '运营商业务列表', keepAlive: false, permission: ['dashboard'] },
          // },
          // {
          //     path: '/mobile/mobile',
          //     name: 'Mobile',
          //     component: () =>
          //         import ('@/views/mobile/mobile/index.vue'),
          //     meta: { title: '运营商业务列表', keepAlive: false, permission: ['dashboard'] },
          // },
          {
            path: '/mobile/date',
            name: 'MobileDate',
            component: () => import('@/views/mobile/mobile/date.vue'),
            meta: { title: '重设生效日期', keepAlive: true, permission: ['dashboard'] },
          },
          {
            path: '/opStatisticsMonthSale/index.vue',
            name: 'OpStatisticsMonthSale',
            component: () => import('@/views/mobile/opStatisticsMonthSale/index.vue'),
            meta: { title: '运营业务月度统计酬金和积分', keepAlive: true, permission: ['dashboard'] },
          },
        ],
      },

      {
        path: '/cashier',
        name: 'Cashier',
        redirect: '/cashier/account',
        component: RouteView,
        meta: { title: '前台账务', keepAlive: true, icon: 'desktop', permission: ['dashboard'] },
        children: [
          {
            path: '/cashier/historySaleRecord/index',
            name: 'historySaleRecordIndex',
            component: () => import('@/views/cashier/historySaleRecord/index.vue'),
            meta: { title: '历史销售记录', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/cashier/sell',
            name: 'CashierSell',
            redirect: '/cashier/sell/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '销售下单', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/cashier/sell/index',
                name: 'CashierSellIndex',
                component: () => import('@/views/cashier/sell/index.vue'),
                meta: { title: '销售下单', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/cashier/sell/:id',
                name: 'CashierSellDetail',
                component: () => import('@/views/cashier/sell/details.vue'),
                meta: { title: '销售下单', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/cashier/sellRecordMyCreateOrder',
            name: 'SellRecordMyCreateOrder',
            redirect: '/cashier/sellRecordMyCreateOrder/index',
            component: BlankLayout,
            meta: { title: '销售记录', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/cashier/sellRecordMyCreateOrder/index',
                name: 'SellRecordMyCreateOrderIndex',
                component: () => import('@/views/cashier/sellRecordMyCreateOrder/index.vue'),
                meta: { title: '我下单的', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/cashier/sellRecordMySellOrder/index',
                name: 'SellRecordMySellOrderIndex',
                component: () => import('@/views/cashier/sellRecordMySellOrder/index.vue'),
                meta: { title: '我销售的 ', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/cashier/sellRecordShop',
                name: 'SellRecordShop',
                redirect: '/cashier/sellRecordShop/index',
                component: BlankLayout,
                hideChildrenInMenu: true,
                meta: { title: '店面记录', keepAlive: true, permission: ['dashboard'] },
                children: [
                  {
                    path: '/cashier/sellRecordShop/index',
                    name: 'SellRecordShopIndex',
                    component: () => import('@/views/cashier/sellRecordShop/index.vue'),
                    meta: { title: '店面记录', keepAlive: false, permission: ['dashboard'] },
                  },
                  {
                    path: '/cashier/sellRecordShop/:id',
                    name: 'SellRecordShopDetail',
                    component: () => import('@/views/cashier/sellRecordShop/details.vue'),
                    meta: { title: '店面记录详情', keepAlive: false, permission: ['dashboard'] },
                  },
                  {
                    path: '/cashier/list/:id',
                    name: 'SellRecordShopList',
                    component: () => import('@/views/cashier/sellRecordShop/list.vue'),
                    meta: { title: '店面记录摘要', keepAlive: false, permission: ['dashboard'] },
                  },
                ],
              },
              {
                path: '/cashier/sellPage',
                name: 'SellPageIndex',
                component: BlankLayout,
                redirect: '/cashier/sellPage/index',
                hideChildrenInMenu: true,
                meta: { title: '销售明细 ', keepAlive: true, permission: ['dashboard'] },
                children: [
                  {
                    path: '/cashier/SellPage/index',
                    name: 'SellPageIndex',
                    component: () => import('@/views/cashier/sellPage/index.vue'),
                    meta: { title: '销售明细', keepAlive: false, permission: ['dashboard'] },
                  },
                  {
                    path: '/cashier/SellPage/:id',
                    name: 'SellPageDetails',
                    component: () => import('@/views/cashier/sellPage/details.vue'),
                    meta: { title: '销售明细详情', keepAlive: false, permission: ['dashboard'] },
                  },
                ],
              },
              {
                path: '/cashier/delRecord/index',
                name: 'DelRecordIndex',
                component: () => import('@/views/cashier/delRecord/index.vue'),
                meta: { title: '撤销记录', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/cashier/profile/index/:id',
                name: 'ProfileIndex',
                hidden: true,
                component: () => import('@/views/cashier/profile/index.vue'),
                meta: { title: '订单详情', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/cashier/profile/order/:id',
                name: 'ProfileIndex',
                hidden: true,
                component: () => import('@/views/cashier/profile/order.vue'),
                meta: { title: '支付', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/cashier/account',
            name: 'CashierAccount',
            redirect: '/cashier/account/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '账户列表', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/cashier/account/index',
                name: 'CashierAccountIndex',
                component: () => import('@/views/cashier/account/index.vue'),
                meta: { title: '账户列表', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/cashier/account/collect',
                name: 'CashierAccountCollect',
                component: () => import('@/views/cashier/account/collect.vue'),
                meta: { title: '账务统计', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/cashier/account/:id',
                name: 'CashierAccountDetail',
                component: () => import('@/views/cashier/account/detail.vue'),
                meta: { title: '账户详情', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/cashier/account/order/:id',
                name: 'CashierAccountOrder',
                component: () => import('@/views/cashier/account/orderDetail.vue'),
                meta: { title: '订单详情', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },

          {
            path: '/cashier/createFinanceIncomeShop',
            name: 'CreateFinanceIncomeShop',
            redirect: '/cashier/createFinanceIncomeShop/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '新增收支', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/cashier/createFinanceIncomeShop/index',
                name: 'CreateFinanceIncomeIndex',
                component: () => import('@/views/cashier/createFinanceIncomeShop/shop.vue'),
                meta: { title: '新增收支', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/cashier/createFinanceIncomeShop/create/:id',
                name: 'CreateFinanceIncomeShopIndex',
                component: () => import('@/views/cashier/createFinanceIncomeShop/index.vue'),
                meta: { title: '新增收支', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/cashier/createFinanceIncomeShop/order/:id',
                name: 'CreateFinanceIncomeOrder',
                hidden: true,
                component: () => import('@/views/cashier/createFinanceIncomeShop/order.vue'),
                meta: { title: '支付', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },

          {
            path: '/cashier/paymentRecord',
            name: 'CashierPaymentRecord',
            redirect: '/cashier/paymentRecord/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '收支记录', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/cashier/paymentRecord/index',
                name: 'CashierPaymentRecordIndex',
                component: () => import('@/views/cashier/paymentRecord/index.vue'),
                meta: { title: '收支记录', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/cashier/paymentRecord/:id',
                name: 'CashierPaymentRecordDetails',
                component: () => import('@/views/cashier/paymentRecord/details.vue'),
                meta: { title: '收支记录', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/cashier/paymentRecord/detail/:id',
                name: 'CashierPaymentRecordDetail',
                component: () => import('@/views/cashier/paymentRecord/detail.vue'),
                meta: { title: '收支详情', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/cashier/debt',
            name: 'CashierDebt',
            redirect: '/cashier/debt/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '债务管理', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/cashier/debt/index',
                name: 'CashierDebtIndex',
                component: () => import('@/views/cashier/debt/index.vue'),
                meta: { title: '债务管理', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/cashier/debt/collect',
                name: 'CashierDebtCollect',
                component: () => import('@/views/cashier/debt/collect.vue'),
                meta: { title: '账务统计', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/cashier/debt/:id',
                name: 'CashierDebtDetail',
                component: () => import('@/views/cashier/debt/detail.vue'),
                meta: { title: '账户详情', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/cashier/debt/order/:id',
                name: 'CashierDebtOrder',
                component: () => import('@/views/cashier/debt/orderDetail.vue'),
                meta: { title: '订单详情', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/cashier/longterm',
            name: 'CashierLongterm',
            redirect: '/cashier/longterm/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '长期分摊费用', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/cashier/longterm/index',
                name: 'CashierLongtermIndex',
                component: () => import('@/views/cashier/longterm/index.vue'),
                meta: { title: '长期分摊费用', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/cashier/longterm/:id',
                name: 'CashierLongtermDetail',
                component: () => import('@/views/cashier/longterm/detail.vue'),
                meta: { title: '长期分摊费用详情', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/cashier/shareholder',
            name: 'CashierShareholder',
            redirect: '/cashier/shareholder/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '股东管理', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/cashier/shareholder/index',
                name: 'CashierShareholderIndex',
                component: () => import('@/views/cashier/shareholder/index.vue'),
                meta: { title: '股东管理', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/cashier/shareholder/:id',
                name: 'CashierShareholderDetail',
                component: () => import('@/views/cashier/shareholder/detail.vue'),
                meta: { title: '股东详情', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/cashier/shareholder/record/:id',
                name: 'CashierShareholderRecordDetail',
                component: () => import('@/views/cashier/shareholder/recorddetail.vue'),
                meta: { title: '订单详情', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/cashier/deposit',
            name: 'CashierDeposit',
            redirect: '/cashier/deposit/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '押金管理', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/cashier/deposit/index',
                name: 'CashierDepositIndex',
                component: () => import('@/views/cashier/deposit/index.vue'),
                meta: { title: '押金管理', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/cashier/deposit/:id',
                name: 'CashierDepositDetail',
                component: () => import('@/views/cashier/deposit/detail.vue'),
                meta: { title: '押金详情', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/cashier/orderSearch',
            name: 'CashierOrderSearch',
            // component: BlankLayout,
            hidden: true,
            meta: { title: '订单速查', keepAlive: true, permission: ['dashboard'] },
            component: () => import('@/views/cashier/orderSearch/index.vue'),
          },
          {
            path: '/cashier/mobileSearch',
            name: 'CashierMobileSearch',
            hidden: true,
            component: () => import('@/views/cashier/mobileSearch/index.vue'),
            meta: { title: '运营商业务速查', keepAlive: true, permission: ['dashboard'] },
          },
        ],
      },
      {
        path: '/shift',
        name: 'Shift',
        redirect: '/shift/counterShift/index',
        component: RouteView,
        meta: { title: '调库', keepAlive: true, icon: 'interaction', permission: ['dashboard'] },
        children: [
          {
            path: '/shift/counterShift',
            name: 'CounterShift',
            redirect: '/shift/counterShift/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '柜组调货', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/shift/counterShift/index',
                name: 'CounterShiftIndex',
                component: () => import('@/views/shift/counterShift/index.vue'),
                meta: { title: '柜组调货', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/shift/counterShift/:id',
                name: 'CounterShiftDetails',
                component: () => import('@/views/shift/counterShift/details.vue'),
                meta: { title: '柜组调货', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/shift/counterId/:id',
                name: 'CounterShiftId',
                component: () => import('@/views/shift/counterShift/counterId.vue'),
                meta: { title: '柜组调货', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/shift/counterShiftRecord',
            name: 'CounterShiftRecord',
            redirect: '/shift/counterShiftRecord/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '柜组调货记录', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/shift/counterShiftRecord/index',
                name: 'CounterShiftRecordIndex',
                component: () => import('@/views/shift/counterShiftRecord/index.vue'),
                meta: { title: '柜组调货记录', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/shift/counterShiftRecord/:id',
                name: 'CounterShiftRecordDetails',
                component: () => import('@/views/shift/counterShiftRecord/details.vue'),
                meta: { title: '柜组调货记录', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/shift/counterShiftRecord/detail/:id',
                name: 'ShiftRecordDetail',
                component: () => import('@/views/shift/counterShiftRecord/detail.vue'),
                meta: { title: '调货详情', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/shift/shopShift',
            name: 'shopShift',
            redirect: '/shift/shopShift/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '店面调库', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/shift/shopShift/index',
                name: 'ShopShiftIndex',
                component: () => import('@/views/shift/shopShift/index.vue'),
                meta: { title: '店面调库', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/shift/shopShift/:id',
                name: 'ShopShiftDetails',
                component: () => import('@/views/shift/shopShift/details.vue'),
                meta: { title: '大批量调库', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/shift/shopShift/counterId/:id',
                name: 'counterId',
                component: () => import('@/views/shift/shopShift/counterId.vue'),
                meta: { title: '店面调库', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/shift/shopShiftRecord/edit/:id',
                name: 'CounterShiftRecordEdit',
                component: () => import('@/views/shift/shopShiftRecord/add.vue'),
                meta: { title: '商品调库', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/shift/shopShiftRecord',
            name: 'shopShiftRecord',
            redirect: '/shift/shopShiftRecord/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '店面调库记录', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/shift/shopShiftRecord/index',
                name: 'ShopShiftRecordIndex',
                component: () => import('@/views/shift/shopShiftRecord/index.vue'),
                meta: { title: '店面调库记录', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/shift/shopShiftRecord/:id',
                name: 'ShopShiftRecordDetails',
                component: () => import('@/views/shift/shopShiftRecord/details.vue'),
                meta: { title: '店面调库记录', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/shift/shopShiftRecord/detail/:id',
                name: 'ShiftRecordDetail',
                component: () => import('@/views/shift/shopShiftRecord/detail.vue'),
                meta: { title: '调货详情', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
        ],
      },

      {
        path: '/member',
        name: 'Member',
        redirect: '/member/list/index',
        component: RouteView,
        meta: { title: '会员管理', keepAlive: true, icon: 'interaction', permission: ['dashboard'] },
        children: [
          {
            path: '/member/benefits/index',
            name: 'benefitsIndex',
            component: () => import('@/views/member/benefits/index.vue'),
            meta: { title: '会员权益', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/member/list',
            name: 'MemberList',
            redirect: '/member/list/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '会员列表', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/member/list/index',
                name: 'MemberListIndex',
                component: () => import('@/views/member/list/index.vue'),
                meta: { title: '会员列表', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/member/list/leadin',
                name: 'MemberLeadin',
                component: () => import('@/views/member/list/leadin.vue'),
                meta: { title: '会员导入', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/member/list/detail/:id',
                name: 'MemberListDetial',
                component: () => import('@/views/member/list/detail.vue'),
                meta: { title: '会员详情', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/member/list/markForm/:id',
                name: 'MemberListMarkForm',
                component: () => import('@/views/member/list/markForm.vue'),
                meta: { title: '编辑会员标签', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/member/list/:id/integral',
                name: 'MemberListDetialIntegral',
                component: () => import('@/views/member/list/integral/index.vue'),
                meta: { title: '会员积分', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/member/list/:id/payment',
                name: 'MemberListDetialPayment',
                component: () => import('@/views/member/list/payment/index.vue'),
                meta: { title: '会员预付款', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/member/list/:id/follow',
                name: 'MemberListDetialFollow',
                component: () => import('@/views/member/list/follow/index.vue'),
                meta: { title: '会员跟进', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/member/list/:id/todo',
                name: 'MemberListDetialTodo',
                component: () => import('@/views/member/list/todo/index.vue'),
                meta: { title: '会员待办', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/member/list/:id/sellrecord',
                name: 'MemberListDetialSellrecord',
                component: () => import('@/views/member/list/sellrecord/index.vue'),
                meta: { title: '会员订单', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/member/list/:id/present',
                name: 'MemberListDetialPresent',
                component: () => import('@/views/member/list/present/index.vue'),
                meta: { title: '会员赠送', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/member/list/:id/ha',
                name: 'MemberBenefits',
                component: () => import('@/views/member/list/ha/index.vue'),
                meta: { title: '会员权益', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/member/list/notify',
            name: 'MemberListNotify',
            component: () => import('@/views/member/notify/index.vue'),
            meta: { title: '会员通知', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/member/list/ascription',
            name: 'MemberListAscription',
            component: () => import('@/views/member/ascription/index.vue'),
            meta: { title: '会员归属', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/member/list/chatrecord',
            name: 'MemberListChatrecord',
            component: () => import('@/views/member/chatrecord/index.vue'),
            meta: { title: '聊天记录', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/member/list/chatrecord/detail',
            name: 'MemberListChatrecordDetail',
            component: () => import('@/views/member/chatrecord/detail.vue'),
            meta: { title: '聊天记录详情', keepAlive: false, permission: ['dashboard'], show: false },
          },
          {
            path: '/member/list/custservice',
            name: 'MemberListCustService',
            component: () => import('@/views/member/custservice/index.vue'),
            meta: { title: '客服经理', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/member/list/todo',
            name: 'MemberListTodo',
            component: () => import('@/views/member/todo/index.vue'),
            meta: { title: '我的待办', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/member/list/todoreport',
            name: 'MemberListTodoReport',
            redirect: '/member/list/todoreport/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '待办统计', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/member/list/todoreport/index',
                name: 'MemberListTodoReportIndex',
                component: () => import('@/views/member/todoreport/index.vue'),
                meta: { title: '待办统计', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/member/list/todoreport/:id',
                name: 'MemberListTodoReportOther',
                component: () => import('@/views/member/todoreport/other.vue'),
                meta: { title: '其他待办', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/member/list/scoreactivity',
            name: 'MemberListScoreActivity',
            redirect: '/member/list/scoreactivity/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '积分活动', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/member/list/scoreactivity/index',
                name: 'MemberListScoreActivityIndex',
                component: () => import('@/views/member/scoreactivity/index.vue'),
                meta: { title: '积分活动', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/member/list/scoreactivity/activityDetail/:id',
                name: 'MemberListActivityDetail',
                component: () => import('@/views/member/scoreactivity/activityDetail.vue'),
                meta: { title: '活动详情', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/member/list/scoreactivity/roundDetail/:id',
                name: 'MemberListRoundDetail',
                component: () => import('@/views/member/scoreactivity/roundDetail.vue'),
                meta: { title: '参与详情', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/member/list/scoreactivity/presentScoreActivity/:id',
                name: 'MemberPresentScoreActivity',
                component: () => import('@/views/member/scoreactivity/presentScoreActivity.vue'),
                meta: { title: '赠送积分', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          // {
          //     path: '/member/list/scoreactivity',
          //     name: 'MemberListScoreActivity',
          //     component: () =>
          //         import ('@/views/member/scoreactivity/index.vue'),
          //     meta: { title: '积分活动', keepAlive: true, permission: ['dashboard'] },
          // },
          {
            path: '/member/orderSearch',
            name: 'MemberOrderSearch',
            component: BlankLayout,
            hidden: true,
            meta: { title: '会员速查', keepAlive: true, permission: ['dashboard'] },
            component: () => import('@/views/member/orderSearch/index.vue'),
          },
        ],
      },
      {
        path: '/service',
        name: 'Service',
        redirect: '/service/supplier/index',
        component: RouteView,
        meta: { title: '售后管理', keepAlive: true, icon: 'tool', permission: ['dashboard'] },
        children: [
          {
            path: '/service/supplier',
            name: 'ServiceSupplier',
            redirect: '/service/supplier/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '售后供应商', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/service/supplier/index',
                name: 'ServiceSupplierIndex',
                component: () => import('@/views/service/supplier/index.vue'),
                meta: { title: '售后供应商', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/service/supplier/:id',
                name: 'ServiceSupplierDetail',
                component: () => import('@/views/service/supplier/detail.vue'),
                meta: { title: '售后供应商详情', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/service/supplier/order/:id',
                name: 'ServiceSupplierOrderDetail',
                component: () => import('@/views/service/supplier/orderdetail.vue'),
                meta: { title: '订单详情', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/service/kind',
            name: 'ServiceKind',
            component: () => import('@/views/service/kind/index.vue'),
            meta: { title: '售后分类管理', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/service/goods',
            name: 'ServiceGoods',
            component: () => import('@/views/service/goods/index.vue'),
            meta: { title: '售后业务管理', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/service/createservice',
            name: 'ServiceCreateService',
            redirect: '/service/createservice/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '新增售后', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/service/createservice/index',
                name: 'ServiceCreateServiceIndex',
                component: () => import('@/views/service/createservice/index.vue'),
                meta: { title: '新增售后', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/service/createservice/:id',
                name: 'ServiceCreateServiceCreate',
                component: () => import('@/views/service/createservice/create.vue'),
                meta: { title: '新增售后', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/service/service',
            name: 'ServiceService',
            redirect: '/service/service/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '当前售后', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/service/service/index',
                name: 'ServiceServiceIndex',
                component: () => import('@/views/service/service/index.vue'),
                meta: { title: '当前售后', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/service/service/:id',
                name: 'ServiceServiceDetails',
                component: () => import('@/views/service/service/details.vue'),
                meta: { title: '当前售后', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/service/service/detail/:id',
                name: 'ServiceServiceDetailsDetail',
                component: () => import('@/views/service/service/detail.vue'),
                meta: { title: '售后详情', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/service/service/order/:id',
                name: 'ServiceServiceDetailsOrder',
                component: () => import('@/views/service/compontent/order.vue'),
                meta: { title: '支付', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/service/service/addfollow/:id',
                name: 'ServiceServiceAddFollow',
                component: () => import('@/views/service/service/addfollow.vue'),
                meta: { title: '新增跟进', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/service/servicerecord',
            name: 'ServiceServiceRecord',
            redirect: '/service/servicerecord/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '售后记录', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/service/servicerecord/index',
                name: 'ServiceServiceRecordIndex',
                component: () => import('@/views/service/servicerecord/index.vue'),
                meta: { title: '售后记录', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/service/servicerecord/:id',
                name: 'ServiceServiceRecordDetails',
                component: () => import('@/views/service/servicerecord/details.vue'),
                meta: { title: '售后记录', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/service/servicerecord/detail/:id',
                name: 'ServiceServiceRecordDetailsDetail',
                component: () => import('@/views/service/servicerecord/detail.vue'),
                meta: { title: '售后详情', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/service/servicerecord/order/:id',
                name: 'ServiceServiceDetailsOrder',
                component: () => import('@/views/service/compontent/order.vue'),
                meta: { title: '支付', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/service/servicerecord/addfollow/:id',
                name: 'ServiceServiceRecordAddFollow',
                component: () => import('@/views/service/servicerecord/addfollow.vue'),
                meta: { title: '新增跟进', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/service/setting',
            name: 'ServiceSetting',
            component: () => import('@/views/service/setting/index.vue'),
            meta: { title: '售后设置', keepAlive: false, permission: ['dashboard'] },
          },
        ],
      },

      {
        path: '/inventory',
        name: 'Inventory',
        redirect: '/inventory/start/index',
        component: RouteView,
        meta: { title: '库存盘点', keepAlive: true, icon: 'project', permission: ['dashboard'] },
        children: [
          {
            path: '/inventory/start',
            name: 'Start',
            redirect: '/inventory/start/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '开始盘点', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/inventory/start/index',
                name: 'InventoryStart',
                component: () => import('@/views/Inventory/start/index.vue'),
                meta: { title: '开始盘点', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/inventory/start/shop/:id',
                name: 'InventoryStartShop',
                component: () => import('@/views/Inventory/start/shop.vue'),
                meta: { title: '库存盘点', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/inventory/start/changeInventoryOrder',
                name: 'changeInventoryOrder',
                component: () => import('@/views/Inventory/start/changeInventoryOrder.vue'),
                meta: { title: '盘点清单', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/inventory/record',
            name: 'InventoryRecord',
            redirect: '/inventory/record/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '盘点记录', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/inventory/record/index',
                name: 'InventoryRecordIndex',
                component: () => import('@/views/Inventory/InventoryRecord/index.vue'),
                meta: { title: '盘点记录', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/inventory/record/:id',
                name: 'InventoryRecordRecord',
                component: () => import('@/views/Inventory/InventoryRecord/Record.vue'),
                meta: { title: '盘点记录', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/inventory/record/list/:id',
                name: 'InventoryRecordList',
                component: () => import('@/views/Inventory/InventoryRecord/list.vue'),
                meta: { title: '盘点记录清单', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
        ],
      },

      {
        path: '/finance',
        name: 'Finance',
        redirect: '/finance/advance/index',
        component: RouteView,
        meta: { title: '财务报表', keepAlive: true, icon: 'area-chart', permission: ['dashboard'] },
        children: [
          {
            path: '/finance/advance',
            name: 'Advance',
            redirect: '/finance/advance/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '预付款统计', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/finance/advance/index',
                name: 'FinanceAdvance',
                component: () => import('@/views/finance/advance/index.vue'),
                meta: { title: '预付款统计', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/finance/advance/balance/:id',
                name: 'Balance',
                component: () => import('@/views/finance/advance/balance.vue'),
                meta: { title: '预付款余额', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/finance/profit',
            name: 'Profit',
            redirect: '/finance/profit/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '利润表', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/finance/profit/index',
                name: 'FinanceProfit',
                component: () => import('@/views/finance/profit/index.vue'),
                meta: { title: '利润表', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/finance/profit/:id',
                name: 'FinanceStore',
                component: () => import('@/views/finance/profit/store.vue'),
                meta: { title: '利润表', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/finance/profit/:id/store/:id',
                name: 'FinanceStoreProfit',
                component: () => import('@/views/finance/profit/profit.vue'),
                meta: { title: '利润表', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/finance/statistics/:id',
                name: 'FinanceStoreStatistics',
                component: () => import('@/views/finance/profit/statistics.vue'),
                meta: { title: '利润表', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/finance/static/index',
            name: 'financeStatic',
            component: () => import('@/views/finance/static.vue'),
            meta: { title: '利润总览', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/finance/profitMonthly/index',
            name: 'profitMonthly',
            component: () => import('@/views/finance/profitMonthly.vue'),
            meta: { title: '利润月报', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/finance/reconciliation/index',
            name: 'reconciliation',
            component: () => import('@/views/finance/reconciliation.vue'),
            meta: { title: '财务对账', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/finance/invoice',
            name: 'invoice',
            component: () => import('@/views/finance/invoice.vue'),
            meta: { title: '发票管理', keepAlive: false, permission: ['dashboard'] },
          },
        ],
      },

      {
        path: '/report',
        name: 'Report',
        redirect: '/report/dayReport/index',
        component: RouteView,
        meta: { title: '统计报表', keepAlive: true, icon: 'dashboard', permission: ['dashboard'] },
        children: [
          {
            path: '/report/benefitsReport',
            name: 'benefitsReport',
            redirect: '/report/benefitsReport/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '会员权益统计', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/report/benefitsReport/index',
                name: 'benefitsReportIndex',
                component: () => import('@/views/report/benefitsReport/index.vue'),
                meta: { title: '会员权益', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/report/benefitsReport/:id',
                name: 'benefitsReportDetails',
                component: () => import('@/views/report/benefitsReport/detail.vue'),
                meta: { title: '会员权益详情', keepAlive: false, permission: ['dashboard'] },
              },]
          },
          {
            path: '/report/dayReport',
            name: 'dayReport',
            redirect: '/report/dayReport/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '经营日报', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/report/dayReport/index',
                name: 'DayReportIndex',
                component: () => import('@/views/report/dayReport/index.vue'),
                meta: { title: '经营日报', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/report/dayReport/:id',
                name: 'DayReportDetails',
                component: () => import('@/views/report/dayReport/details.vue'),
                meta: { title: '经营日报详情', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/report/dayReport/order/:id',
                name: 'DayReportDetailsOrder',
                component: () => import('@/views/report/dayReport/detailsList.vue'),
                meta: { title: '商品销售详情', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/report/dayReport/collectionStatic/:id',
                name: 'CollectionStatic',
                component: () => import('@/views/report/dayReport/collectionStatic.vue'),
                meta: { title: '收款统计', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/report/dayReport/memberList/:id',
                name: 'MemberListReport',
                component: () => import('@/views/report/dayReport/memberList.vue'),
                meta: { title: '会员跟进统计详情', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/report/shopReport',
            name: 'ShopReport',
            redirect: '/report/shopReport/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '店面销售统计', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/report/shopReport/index',
                name: 'ShopReportIndex',
                component: () => import('@/views/report/shopReport/index.vue'),
                meta: { title: '店面销售统计', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/report/shopReport/:id',
                name: 'ShopReportDetails',
                component: () => import('@/views/report/shopReport/details.vue'),
                meta: { title: '店面销售统计详情', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/report/shopReport/goodsDetail/:id',
                name: 'ShopReportGoodsDetails',
                component: () => import('@/views/report/shopReport/goodsDetail.vue'),
                meta: { title: '店面销售统计详情', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/report/purchasingReport',
            name: 'PurchasingReport',
            redirect: '/report/purchasingReport/goodsShop',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '商品采购统计', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/report/purchasingReport/goodsShop',
                name: 'PurchasingReportGoodsShop',
                component: () => import('@/views/report/purchasingReport/goodsShop/index.vue'),
                meta: { title: '商品采购统计', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/report/purchasingReport/goodsName',
                name: 'PurchasingReportGoodsName',
                component: () => import('@/views/report/purchasingReport/goodsName/index.vue'),
                meta: { title: '商品名统计', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/report/purchasingReport/goodsSupplier',
                name: 'PurchasingReportGoodsSupplier',
                component: () => import('@/views/report/purchasingReport/goodsSupplier/index.vue'),
                meta: { title: '供应商统计', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/report/purchasingReport/goodsChannel',
                name: 'PurchasingReportGoodsChannel',
                component: () => import('@/views/report/purchasingReport/goodsChannel/index.vue'),
                meta: { title: '渠道统计', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/report/purchasingReport/goodsInStoreDetail/:id',
                name: 'PurchasingReportGoodsInStoreDetail',
                hidden: true,
                component: () => import('@/views/report/purchasingReport/goodsInStoreDetail/index.vue'),
                meta: { title: '采购入库详情', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/report/purchasingReport/goodsOutStoreDetail/:id',
                name: 'PurchasingReportGoodsOutStoreDetail',
                hidden: true,
                component: () => import('@/views/report/purchasingReport/goodsOutStoreDetail/index.vue'),
                meta: { title: '采购出库详情', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/report/SellReport',
            name: 'SellReport',
            redirect: '/report/SellReport/goodsShop',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '商品销售统计', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/report/SellReport/goodsShop',
                name: 'SellReportGoodsShop',
                component: () => import('@/views/report/SellReport/goodsShop/index.vue'),
                meta: { title: '商品销售统计', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/report/SellReport/goodsUser',
                name: 'SellReportGoodsUser',
                component: () => import('@/views/report/SellReport/goodsUser/index.vue'),
                meta: { title: '员工统计', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/report/SellReport/goodsSupplier',
                name: 'SellReportGoodsSupplier',
                component: () => import('@/views/report/SellReport/goodsSupplier/index.vue'),
                meta: { title: '供应商统计', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/report/SellReport/goodsChannel',
                name: 'SellReportGoodsChannel',
                component: () => import('@/views/report/SellReport/goodsChannel/index.vue'),
                meta: { title: '渠道统计', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/report/SellReport/goodsBrand',
                name: 'SellReportGoodsBrand',
                component: () => import('@/views/report/SellReport/goodsBrand/index.vue'),
                meta: { title: '品牌汇总', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/report/SellReport/goodsKind',
                name: 'SellReportGoodsKind',
                component: () => import('@/views/report/SellReport/goodsKind/index.vue'),
                meta: { title: '分类汇总', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/report/SellReport/goodsPrice',
                name: 'SellReportGoodsPrice',
                component: () => import('@/views/report/SellReport/goodsPrice/index.vue'),
                meta: { title: '价格段统计', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/report/SellReport/goodsDetail/:id',
                name: 'SellReportGoodsDetail',
                hidden: true,
                component: () => import('@/views/report/SellReport/goodsDetail/index.vue'),
                meta: { title: '商品销售统计详情', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/report/Analyse',
            name: 'Analyse',
            redirect: '/report/analyse/brandTurnover',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '商品周转分析', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/report/analyse/brandTurnover',
                name: 'AnalyseBrandTurnover',
                component: () => import('@/views/report/Analyse/brandTurnover/index.vue'),
                meta: { title: '商品周转分析', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/report/analyse/kindTurnover',
                name: 'AnalyseKindTurnover',
                component: () => import('@/views/report/Analyse/kindTurnover/index.vue'),
                meta: { title: '分类', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/report/analyse/goodsTurnover',
                name: 'AnalyseGoodsTurnover',
                component: () => import('@/views/report/Analyse/goodsTurnover/index.vue'),
                meta: { title: '商品名', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/report/sellTrendShop',
            name: 'sellTrendShop',
            redirect: '/report/sellTrendShop/duration',
            component: BlankLayout,
            meta: { title: '销售趋势看板', keepAlive: true, permission: ['dashboard'] },
            hideChildrenInMenu: true,
            children: [
              {
                path: '/report/sellTrendShop/duration',
                name: 'AnalyseDuration',
                component: () => import('@/views/report/sellTrendShop/duration/index.vue'),
                meta: { title: '销售趋势看板', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/report/sellTrendShop/sellTrendUser',
                name: 'AnalyseSellTrendUser',
                component: () => import('@/views/report/sellTrendShop/sellTrendUser/index.vue'),
                meta: { title: '员工趋势', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/report/presentShop',
            name: 'Present',
            redirect: '/report/presentShop/presentShop',
            component: BlankLayout,
            meta: { title: '礼品赠送统计', keepAlive: true, permission: ['dashboard'] },
            hideChildrenInMenu: true,
            children: [
              {
                path: '/report/presentShop/presentShop',
                name: 'SellReportPresentShop',
                component: () => import('@/views/report/SellReport/presentShop/index.vue'),
                meta: { title: '礼品赠送统计', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/report/presentShop/presentUser',
                name: 'SellReportPresentUser',
                component: () => import('@/views/report/SellReport/presentUser/index.vue'),
                meta: { title: '员工统计', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/report/SellReport/presentDetail/:id',
                name: 'presentDetail',
                hidden: true,
                component: () => import('@/views/report/SellReport/presentDetail/index.vue'),
                meta: { title: '礼品赠送统计详情', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/report/report',
            name: 'MobileShop',
            redirect: '/report/report/mobileShop',
            component: BlankLayout,
            meta: { title: '运营商业务统计', keepAlive: true, permission: ['dashboard'] },
            hideChildrenInMenu: true,
            children: [
              {
                path: '/report/report/mobileShop',
                name: 'ReportMobileShop',
                component: () => import('@/views/report/Report/mobileShop/index.vue'),
                meta: { title: '运营商业务统计', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/report/report/mobileUser',
                name: 'ReportMobileUser',
                component: () => import('@/views/report/Report/mobileUser/index.vue'),
                meta: { title: '员工统计', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/report/report/mobileBrand',
                name: 'ReportMobileBrand',
                component: () => import('@/views/report/Report/mobileBrand/index.vue'),
                meta: { title: '运营商统计', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/report/report/mobileChannel',
                name: 'ReportMobileChannel',
                component: () => import('@/views/report/Report/mobileChannel/index.vue'),
                meta: { title: '渠道统计', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/report/report/mobileEid',
                name: 'ReportMobileEid',
                component: () => import('@/views/report/Report/mobileEid/index.vue'),
                meta: { title: '工号统计', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/report/report/mobileNameReport',
                name: 'ReportMobileNameReport',
                component: () => import('@/views/report/Report/mobileNameReport/index.vue'),
                meta: { title: '业务统计', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/report/report/mobileKind/:id',
                name: 'ReportMobileKind',
                component: () => import('@/views/report/Report/mobileKind/index.vue'),
                meta: { title: '分类统计', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/report/report/mobileDetail/:id',
                name: 'ReportMobileDetail',
                hidden: true,
                component: () => import('@/views/report/Report/mobileDetail/index.vue'),
                meta: { title: '运营商业务统计详情', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/report/Report',
            name: 'ServiceTal',
            redirect: '/report/Report/serviceShop',
            component: BlankLayout,
            meta: { title: '售后统计', keepAlive: true, permission: ['dashboard'] },
            hideChildrenInMenu: true,
            children: [
              {
                path: '/report/Report/serviceShop',
                name: 'ReportServiceShop',
                component: () => import('@/views/report/Report/serviceShop/index.vue'),
                meta: { title: '售后统计', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/report/Report/serviceUser',
                name: 'ReportServiceUser',
                component: () => import('@/views/report/Report/serviceUser/index.vue'),
                meta: { title: '员工统计', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/report/Report/goodsDetail/:id',
                name: 'ReportGoodsDetail',
                hidden: true,
                component: () => import('@/views/report/Report/goodsDetail/index.vue'),
                meta: { title: '售后统计详情', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/report/wechatShop',
            name: 'WechatShop',
            component: BlankLayout,
            meta: { title: '微信推广统计', keepAlive: true, permission: ['dashboard'] },
            component: () => import('@/views/report/wechatShop/index.vue'),
          },
          {
            path: '/report/memberShop',
            name: 'MemberShop',
            component: BlankLayout,
            meta: { title: '会员跟进统计', keepAlive: true, permission: ['dashboard'] },
            component: () => import('@/views/report/memberShop/index.vue'),
          },
          {
            path: '/report/memberDetail/:id',
            name: 'MemberDetail',
            hidden: true,
            component: BlankLayout,
            meta: { title: '会员跟进统计详情', keepAlive: true, permission: ['dashboard'] },
            component: () => import('@/views/report/memberDetail/index.vue'),
          },
          {
            path: '/report/wholesaleShop',
            name: 'WholesaleShop',
            component: BlankLayout,
            meta: { title: '商品批发统计', keepAlive: true, permission: ['dashboard'] },
            component: () => import('@/views/report/wholesaleShop/index.vue'),
          },
          {
            path: '/report/wholesaleDetail/:id',
            name: 'WholesaleDetail',
            hidden: true,
            component: BlankLayout,
            meta: { title: '商品批发统计详情', keepAlive: true, permission: ['dashboard'] },
            component: () => import('@/views/report/wholesaleDetail/index.vue'),
          },
          {
            path: '/report/shiftPhone',
            name: 'ShiftPhone',
            component: BlankLayout,
            meta: { title: '商品调库统计', keepAlive: true, permission: ['dashboard'] },
            component: () => import('@/views/report/shiftPhone/index.vue'),
          },
          {
            path: '/report/shiftPhoneShop/:id',
            name: 'ShiftPhoneShop',
            hidden: true,
            component: BlankLayout,
            meta: { title: '商品调库统计详情', keepAlive: true, permission: ['dashboard'] },
            component: () => import('@/views/report/shiftPhoneShop/index.vue'),
          },
          {
            path: '/report/shiftPhoneDetail/:id',
            name: 'ShiftPhoneDetail',
            hidden: true,
            component: BlankLayout,
            meta: { title: '调库记录', keepAlive: true, permission: ['dashboard'] },
            component: () => import('@/views/report/shiftPhoneDetail/index.vue'),
          },
          {
            path: '/report/shopSalesShop',
            name: 'ShopSalesShop',
            redirect: '/report/shopSalesShop/shop',
            component: BlankLayout,
            meta: { title: '优品销售统计', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/report/shopSalesShop/shop',
                name: 'ShopSalesShopShop',
                component: () => import('@/views/report/shopSalesShop/index.vue'),
                meta: { title: '门店统计', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/report/shopSalesShop/user',
                name: 'ShopSalesShopUser',
                component: () => import('@/views/report/shopSalesShop/user.vue'),
                meta: { title: '员工统计', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/report/shopSalesShop/supply',
                name: 'ShopSalesShopSupply',
                component: () => import('@/views/report/shopSalesShop/supply.vue'),
                meta: { title: '供应商统计', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/report/shopSalesShop/:id',
                name: 'ShopSalesShopDetail',
                hidden: true,
                component: () => import('@/views/report/shopSalesShop/detail.vue'),
                meta: { title: '优品销售统计详情', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/report/secondHandPurchaseSupplier',
            name: 'SecondHand',
            redirect: '/report/secondHandPurchaseSupplier',
            component: BlankLayout,
            meta: { title: '优品采购统计', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/report/secondHandPurchaseSupplier',
                name: 'SecondHandPurchaseSupplier',
                component: () => import('@/views/report/secondHandPurchaseSupplier/index.vue'),
                meta: { title: '供应商统计', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/report/profit/index',
            name: 'profitFront',
            component: () => import('@/views/report/profit/index.vue'),
            meta: { title: '销售统计', keepAlive: false, permission: ['dashboard'] },
          },
        ],
      },
      {
        path: '/system',
        name: 'System',
        redirect: '/system/message',
        component: RouteView,
        meta: { title: '系统设置', keepAlive: true, icon: 'setting', permission: ['dashboard'] },
        children: [
          {
            path: '/system/notice',
            name: 'Notice',
            component: () => import('@/views/system/notice/index.vue'),
            meta: { title: '公告设置', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/system/safe',
            name: 'Safe',
            component: () => import('@/views/system/safe/index.vue'),
            meta: { title: '安全设置', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/system/receiptpay',
            name: 'Receiptpay',
            component: () => import('@/views/system/receiptpay/index.vue'),
            meta: { title: '收支类型设置', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/system/paytype',
            name: 'Paytype',
            component: () => import('@/views/system/paytype/index.vue'),
            meta: { title: '收款方式管理', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/system/diff',
            name: 'Diff',
            component: () => import('@/views/system/diff/index.vue'),
            meta: { title: '分摊设置', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/system/print',
            name: 'Print',
            component: () => import('@/views/system/print/index.vue'),
            meta: { title: '打印设置', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/system/paytype/payclassify',
            name: 'Payclassify',
            hidden: true,
            component: () => import('@/views/system/paytype/classify.vue'),
            meta: { title: '分类管理', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/system/paytype/shoppay',
            name: 'Shoppay',
            hidden: true,
            component: () => import('@/views/system/paytype/shoppay.vue'),
            meta: { title: '门店收款管理', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/system/paytype/shoppayset/:id',
            name: 'Shoppayset',
            hidden: true,
            component: () => import('@/views/system/paytype/shoppayset.vue'),
            meta: { title: '门店收款设置', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/system/paytype/shopset/:id',
            name: 'Shopset',
            hidden: true,
            component: () => import('@/views/system/paytype/shopset.vue'),
            meta: { title: '收款门店设置', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/system/wechat',
            name: 'Wechat',
            redirect: '/system/wechat/index',
            component: BlankLayout,
            hideChildrenInMenu: true,
            meta: { title: '微信设置', keepAlive: true, permission: ['dashboard'] },
            children: [
              {
                path: '/system/wechat/index',
                name: 'WechatIndex',
                component: () => import('@/views/system/wechat/index.vue'),
                meta: { title: '微信设置', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/system/wechat/detail',
                name: 'WechatDetail',
                hidden: true,
                component: () => import('@/views/system/wechat/detail.vue'),
                meta: { title: '微信设置', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/system/wechat/memberCard',
                name: 'WechatmMmberCard',
                hidden: true,
                component: () => import('@/views/system/wechat/memberCard.vue'),
                meta: { title: '微信会员卡设置', keepAlive: false, permission: ['dashboard'] },
              },
              {
                path: '/system/wechat/memberLevel',
                name: 'WechatmMemberLevel',
                hidden: true,
                component: () => import('@/views/system/wechat/memberLevel.vue'),
                meta: { title: '会员等级设置', keepAlive: false, permission: ['dashboard'] },
              },
            ],
          },
          {
            path: '/system/message',
            name: 'SysMessage',
            component: () => import('@/views/system/message/index.vue'),
            meta: { title: '消息设置', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/system/score',
            name: 'Score',
            component: () => import('@/views/system/score/index.vue'),
            meta: { title: '积分设置', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/system/regional',
            name: 'Regional',
            component: () => import('@/views/system/regional/index.vue'),
            meta: { title: '区域设置', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/system/tags',
            name: 'Tags',
            component: () => import('@/views/system/tags/index.vue'),
            meta: { title: '会员标签', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/system/tagGroup',
            name: 'TagGroup',
            hidden: true,
            component: () => import('@/views/system/tagGroup/index.vue'),
            meta: { title: '标签组设置', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/system/inventory',
            name: 'SystemInventory',
            component: () => import('@/views/system/inventory/index.vue'),
            meta: { title: '期初库存', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/system/autoReview',
            name: 'AutoReview',
            component: () => import('@/views/system/autoReview/index.vue'),
            meta: { title: '自动审核', keepAlive: false, permission: ['dashboard'] },
          },
        ],
      },
    ],
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true,
  },
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/user',
    component: UserLayout,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Login'),
      },
      {
        path: 'register',
        name: 'register',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Register'),
      },
      {
        path: 'loading',
        name: 'loading',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/loading'),
      },
      {
        path: 'register-result',
        name: 'registerResult',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/RegisterResult'),
      },
      {
        path: 'recover',
        name: 'recover',
        component: undefined,
      },
    ],
  },

  {
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404'),
  },
  {
    path: '/orderNotice/:id',
    name: 'orderNotice',
    component: () => import(/* webpackChunkName: "user" */ '@/views/system/wechat/orderNotice.vue'),
    meta: { title: '电子票据', keepAlive: false, permission: ['dashboard'] },
  },
]
