<template>
  <global-footer class="footer custom-render">
    <template v-slot:links>
      <!-- <a href="https://www.github.com/vueComponent/pro-layout" target="_blank">Pro Layout</a> -->
      <div>
        Copyright© 2025 福建华软智通科技有限公司 ALL Rights Reserved
        <a href="https://beian.miit.gov.cn" target="_blank">闽ICP备2024062617号-1</a>
      </div>

      <!-- <a href="https://www.github.com/sendya/" target="_blank">@Sendya</a> -->
    </template>
    <template v-slot:copyright>
      <!-- <a href="https://github.com/vueComponent" target="_blank">福州比目鱼信息技术有限公司</a> -->
    </template>
  </global-footer>
</template>

<script>
import { GlobalFooter } from '@ant-design-vue/pro-layout'

export default {
  name: 'ProGlobalFooter',
  components: {
    GlobalFooter,
  },
}
</script>
